<template>
  <form class="signin-form form view-login-form">
    <form-group :field="$v.account.iin" :label="$t('profile.iin-label')" name="iin">
      <el-input
          maxlength="12"
          :placeholder="$t('profile.iin-label')"
          v-model="account.iin"
          v-mask="'############'"
          @change="$v.account.iin.$touch()"
          autocomplete="off">
      </el-input>
    </form-group>
    <div>
      <form-group :field="$v.account.password" :label="$t('signin-form.password-label')" name="password">
        <el-input
            maxlength="8"
            type="number"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            :placeholder="$t('signin-form.password-placeholder')"
            v-model="account.password"
            @input="onPasswordInput"
            @change="$v.account.password.$touch()"
            autocomplete="off"
            show-password
            @keyup.enter.native="submit(account)">
        </el-input>
      </form-group>
      <div class="row form-group mt-4">
        <div class="col-6">
          <el-checkbox :disabled="iinLoading">{{ $t('signin-form.remember-me') }}</el-checkbox>
        </div>
        <div class="col-6 text-right">
          <router-link to="/forget-password-iin" tag="el-link" style="text-decoration: none" :disabled="iinLoading">
            {{ $t('signin-form.forget-password') }}
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <router-link to="#" @click.native="routeSignupHandler" tag="el-link" style="text-decoration: none" :disabled="iinLoading">
            {{ $t('signin-form.dont-have-account') }} <span class="text-primary">{{ $t('signin-form.signup') }}</span>
          </router-link>
        </div>
      </div>
      <div class="form-group text-center pt-3">
        <el-button type="primary" @click.prevent="submit(account)" :loading="loading"
                   :disabled="account.password.length < 8 || account.iin.length < 12">{{ $t('signin-form.signin-button') }}
        </el-button>
      </div>
    </div>

  </form>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {required, minLength} from 'vuelidate/lib/validators'

export default {
  name: 'LoginForm',
  beforeCreate() {
    Vue.use(VueMask);
  },
  data() {
    return {
      iinLoading: false,
      loading: false,
      iinArray: [],
      account: {
        iin: '',
        password: ''
      }
    }
  },
  methods: {
    async routeSignupHandler() {
      try {
        const res = await this.$http.get(`${window.API_ROOT}/api/check-time`)
        if (res.body.code === 200) {
          this.$router.push('/signup/first-step')
        }
        if (res.body.code === 403) {
          Vue.toastr({
            message: '',
            // description: this.$t('navbar.registration-alert').replace('year', new Date().getFullYear()),
            description: this.$t('navbar.registration-alert'),
            type: 'info'
          });
        }
      } catch (error) {
        Vue.toastr({
          message: this.$t('error'),
          description: error,
          type: 'error'
        });
      }
    },
    close() {
      this.$emit('close')
    },
    onPasswordInput(e) {
      if (e.match(/[^A-Za-z0-9!.\-_?]/gi)) {
        Vue.toastr({
          message: this.$t('error'),
          description: this.$t('reset-password.enter-only-latin-letters'),
          type: 'error'
        })
      }
      ;
      this.account.password = e.replace(/[^A-Za-z0-9!.\-_?]/gi, "");
    },
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.loading = true;
      this.login(this.account)
          .then(() => {
            this.loading = false;
            sessionStorage.removeItem('children');

            this.$router.push(this.$route.query.redirect || '/cabinet')
          })
          .catch((e) => {
            this.loading = false;
            // Vue.toastr({
            //   description: this.$t('login-reg', {arrayRegions: e.body.region}),
            //   type: 'error'
            // })
          })
    },
    ...mapActions({
      'getIin': 'getIin',
      'login': 'login'
    })
  },
  validations: {
    account: {
      iin: {
        required: false,
        minLength: minLength(12)
      },
      password: {
        required: false,
        minLength: minLength(8)
      }
    }
  }
}
</script>

<style>
.el-select-dropdown__empty {
  padding: 10px;
}
</style>